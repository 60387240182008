import React, { useMemo, useState } from 'react';

import { AuthContext, FlowsContext } from './context';
import { AuthProviderProps, FlowsContextProps } from './context.types';
import { FlowDto, ExecutionDto } from 'axios-facade/api-facade.types';
import { TemplateDto } from 'axios-facade/MarketTemplates.types';

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(false);
	const [search, setSearch] = useState<string>('');
	const [tags, setTags] = useState<string[]>([]);
	const [reloadFlow, setReloadFlow] = useState<boolean>(false);
	const value = useMemo(
		() => ({
			loading,
			setLoading,
			search,
			setSearch,
			tags,
			setTags,
			reloadFlow,
			setReloadFlow,
		}),
		[loading, reloadFlow, search, tags],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const FlowsProvider = ({ children }: any): JSX.Element => {
	const [active, setActive] = useState<FlowDto[]>([]);
	const [available, setAvailable] = useState<TemplateDto[]>([]);
	const [completed, setCompleted] = useState<ExecutionDto[]>([]);
	const [recommended, setRecommended] = useState<TemplateDto[]>([]);

	const value = useMemo(
		() =>
			({
				active,
				setActive,
				available,
				setAvailable,
				completed,
				setCompleted,
				recommended,
				setRecommended,
				clearAll() {
					setActive([]);
					setCompleted([]);
					setRecommended([]);
					setAvailable([]);
				},
			} as FlowsContextProps),
		[active, available, completed, recommended],
	);

	return (
		<FlowsContext.Provider value={value}>{children}</FlowsContext.Provider>
	);
};

const GlobalProvider = ({ children }: any) => {
	return (
		<AuthProvider>
			<FlowsProvider>{children}</FlowsProvider>
		</AuthProvider>
	);
};

export default GlobalProvider;
