import classNames from 'classnames';
import { Routes } from 'react-router-dom';
import React, { useContext } from 'react';

import { Spinner } from '../components/spinner/Spinner';

import { IAppRoute } from './App.model';
import { AuthContext } from './context/context';

type Props = {
	routesList: IAppRoute[];
	generateRoutes: (routes: IAppRoute[]) => JSX.Element[];
};
const AppView = (props: Props) => {
	const { loading } = useContext(AuthContext);

	return (
		<div
			className={classNames('flex-1 flex-col bg-mirage relative', {
				relative: loading,
			})}
		>
			{loading && <Spinner />}

			<React.Suspense
				fallback={
					<div>
						<div>Loading</div>
					</div>
				}
			>
				<Routes>{props.generateRoutes(props.routesList)}</Routes>
			</React.Suspense>
		</div>
	);
};

export default AppView;
