import './Spinner.css';

import React from 'react';

export function Spinner() {
	return (
		<>
			<div className="s0-spinner fixed w-full h-full bg-black bg-opacity-50 top-0 left-0" />
			<div className="s0-spinner absolute inset-x-0 mt-20 mx-auto loader ease-linear rounded-full border-8 border-t-8 h-16 w-16" />
		</>
	);
}
