import { Navigate, Route, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useContext } from 'react';

import AppView from './App.view';

import Auth, { syncExtensionToken } from './auth/auth';
import useRoutes, { IRoute } from '../helpers/hooks/use-routes';
import { AppRouteType, IAppRoute } from './App.model';
import GlobalProvider from './context/provider';
import { FlowsContext } from './context/context';

function App() {
	const routes = useRoutes();
	const location = useLocation();
	const flowsContext = useContext(FlowsContext);

	/*
	 * sync auth token for chrome extension
	 */
	useEffect(() => {
		window.addEventListener('load', () => {
			setTimeout(() => {
				syncExtensionToken(Auth.token);
			}, 1000);
		});
	}, []);

	useEffect(() => {
		if (location.pathname === '/login') {
			flowsContext.clearAll();
		}
	}, [location, flowsContext]);

	const prepareRouteList = (routes: IRoute[]): IAppRoute[] => {
		return routes.reduce((acc: IAppRoute[], route: IRoute) => {
			if (
				route.guard &&
				!route.guard.every((guard) => guard({ accessToken: Auth.token }))
			) {
				if (route.fallback) {
					const contentRoute: IAppRoute = {
						type: AppRouteType.Fallback,
						path: route.path,
						to: route.fallback,
						children: route.children ? prepareRouteList(route.children) : null,
					};

					return [...acc, contentRoute];
				}
			}

			const component: React.FC | undefined = route.component;

			const contentRoute: IAppRoute = {
				type: AppRouteType.Page,
				path: route.path,
				component,
				children: route.children ? prepareRouteList(route.children) : null,
			};

			return [...acc, contentRoute];
		}, []);
	};

	const generateRoutes = useCallback(
		(routes: IAppRoute[]) =>
			routes.map((route: IAppRoute) => {
				const Component: React.FC | undefined = route['component'];
				const jsx: JSX.Element = route['jsx'];
				return (
					<Route
						key={route.path}
						path={route.path}
						element={
							route.type === AppRouteType.Fallback ? (
								<Navigate to={route.to}></Navigate>
							) : (
								<React.Fragment>
									{Component ? <Component></Component> : jsx}
								</React.Fragment>
							)
						}
					>
						{route.children ? generateRoutes(route.children) : null}
					</Route>
				);
			}),
		[],
	);

	return (
		<GlobalProvider>
			<AppView
				generateRoutes={generateRoutes}
				routesList={prepareRouteList(routes)}
			></AppView>
		</GlobalProvider>
	);
}

export default App;
