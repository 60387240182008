import jwt from 'jsonwebtoken';
import { ProfileDto, TokenDto } from 'reactive-variables/loading';

interface JwtPayload {
	role: string;
	app: string;
	company: string;
	email: string;
	exp: number;
	iat: number;
}

const Auth = {
	token: localStorage.getItem('accessToken') || '',
	refreshToken: localStorage.getItem('refreshToken') || '',
	user: localStorage.getItem('user') || '',
	profile: localStorage.getItem('profile') || '',
	authenticate(data: TokenDto) {
		// accessToken(data.accessToken);
		this.token = data.accessToken;
		this.refreshToken = data.refreshToken;
		syncExtensionToken(data.accessToken);
		localStorage.setItem('accessToken', data.accessToken);
		localStorage.setItem('refreshToken', data.refreshToken);
		this.setUser();
	},
	signout() {
		// accessToken('');
		this.token = '';
		this.refreshToken = '';
		syncExtensionToken('');
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('user');
		localStorage.removeItem('profile');
	},
	setUser() {
		const payload = jwt.decode(this.token) as JwtPayload | null;
		if (payload) {
			const user = JSON.stringify(payload);
			localStorage.setItem('user', user);
			this.user = user;
		}
	},
	setProfile(profile: ProfileDto) {
		const stringProfile = JSON.stringify(profile);
		localStorage.setItem('profile', stringProfile);
		this.profile = stringProfile;
	},
	getUserProfile(): ProfileDto {
		return this.profile ? JSON.parse(this.profile) : {};
	},
	getVncUrl(): string {
		return this.getVpc()?.url || 'http://127.0.0.1';
	},
	getVncPassword(): string {
		return this.getVpc()?.password || '';
	},
	getVpc() {
		return this.getUserProfile()?.vpc || {};
	},
	getUserProviders() {
		return this.getUserProfile().providers || [];
	},
	getUser(): JwtPayload {
		return this.user ? JSON.parse(this.user) : this.signout();
	},
	getAuth() {
		return !!this.token;
	},
	getRefreshToken(): string {
		return this.refreshToken;
	},
	getToken(): string {
		return this.token;
	},
};

export const syncExtensionToken = (token: string) => {
	const tokenEvent = new CustomEvent('onTokenChange', {
		detail: {
			token,
		},
	});

	document.dispatchEvent(tokenEvent);
};

export default Auth;
