import { createContext } from 'react';
import { AuthContextProps, FlowsContextProps } from './context.types';

export const AuthContext = createContext<AuthContextProps>({
	loading: false,
	setLoading: () => null,
	search: '',
	setSearch: () => null,
	tags: [],
	setTags: () => null,
	reloadFlow: false,
	setReloadFlow: () => null,
});

export const FlowsContext = createContext<FlowsContextProps>({
	active: [],
	setActive: () => null,
	available: [],
	setAvailable: () => null,
	completed: [],
	setCompleted: () => null,
	recommended: [],
	setRecommended: () => null,
	clearAll: () => null,
});
