import React, { useMemo } from 'react';
import guards from '../routes/guards';

import { GuardData } from 'helpers/routes/guards/guard.model';

const Dashboard = React.lazy(() => import('pages/dashboard/dashboard'));
const DashboardReports = React.lazy(
	() => import('pages/dashboard/dashboard-reports/DashboardReports'),
);
const Authenticated = React.lazy(
	() => import('app/authenticated/Authenticated'),
);
const SignupForm = React.lazy(
	() => import('containers/signup-form-multistep/signup-form'),
);
const LoginForm = React.lazy(() => import('containers/login-form/LoginForm'));
const TemplateForm = React.lazy(() => import('containers/template/Template'));
const Marketplace = React.lazy(
	() => import('containers/marketplace/marketplace'),
);
const CreateFlow = React.lazy(
	() => import('components/create-flow-dialog/CreateFlow'),
);

const Flow = React.lazy(() => import('pages/flow/flow'));
const Users = React.lazy(() => import('pages/users/users'));

type ComponentRoute = BaseRoute & {
	component?: React.FC;
	jsx?: JSX.Element;
};

interface BaseRoute {
	path: string;
	name?: string;
	module?: { code: string; action: string; fallback?: string } | string;
	guard?: ((guardData: GuardData) => boolean)[];
	fallback?: string;
	children?: IRoute[] | null;
}

export enum RoutePaths {
	Absolute = '*',
	ChildAbsolute = '/*',
	Home = '/',
	Login = '/login',
	SignUp = '/auth/signup',
	Dashboard = 'dashboard',
	Feed = 'feed',
	Users = 'users',
	Flow = 'flow',
	Marketplace = 'marketplace',
	TemplateForm = 'marketplace/:id',
	MarketplaceNew = 'marketplace/new',
	CreateFlow = 'flows/:id',
}

const useRoutes = (): IRoute[] => {
	return useMemo(() => {
		return [
			{
				path: RoutePaths.Absolute,
				component: LoginForm,
				guard: [guards.guestGuard],
			},
			{
				path: RoutePaths.Login,
				component: LoginForm,
				guard: [guards.guestGuard],
			},
			{
				path: RoutePaths.SignUp,
				component: SignupForm,
				guard: [guards.guestGuard],
			},
			{
				path: RoutePaths.ChildAbsolute,
				component: Authenticated,
				guard: [guards.authGuard],
				fallback: RoutePaths.Login,
				children: [
					{
						path: RoutePaths.Absolute,
						component: Dashboard,
						guard: [],
						fallback: RoutePaths.Dashboard,
					},
					{ path: RoutePaths.Dashboard, component: Dashboard, guard: [] },
					{
						path: RoutePaths.Feed,
						component: DashboardReports,
						guard: [],
					},
					{
						path: `${RoutePaths.Flow}/:id`,
						component: Flow,
						guard: [],
					},
					{ path: RoutePaths.Users, component: Users, guard: [] },
					{ path: RoutePaths.Marketplace, component: Marketplace, guard: [] },
					{ path: RoutePaths.TemplateForm, component: TemplateForm, guard: [] },
					{ path: RoutePaths.CreateFlow, component: CreateFlow, guard: [] },
				],
			},
		];
	}, []);
};

export type IRoute = ComponentRoute;

export default useRoutes;
